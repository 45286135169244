import { type FC, useCallback } from 'react';
import { Grid, Stack } from '@mui/material';
import {
  Button,
  FormProvider,
  SelectField,
  TextField,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { StopsSearchParams } from 'dto/stop';
import { Icon } from '@fleet/shared/mui';
import type { Config as FormConfig } from 'final-form';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import _xor from 'lodash/xor';
import { TransAlert } from 'i18n/trans/alert';

interface StopSearchFormProps {
  onFilterSubmit: (filter: StopsSearchParams) => void;
}

export const StopSearchForm: FC<StopSearchFormProps> = ({ onFilterSubmit }) => {
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);

  const onSubmit = useCallback<FormConfig<StopsSearchParams>['onSubmit']>(
    (values, form) => {
      const required = _xor(form.getRegisteredFields(), ['countryId']);
      if (_isEmpty(_filter(_pick(values, required), Boolean))) {
        return required.reduce(
          (acc, name) => ({
            ...acc,
            [name]: <TransAlert i18nKey="fillAtLeastOne" />,
          }),
          {}
        );
      }
      return formSubmit(async () => onFilterSubmit(values));
    },
    [onFilterSubmit]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<StopsSearchParams>({
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={3} spacing={2} alignItems="flex-end">
          <Grid item xs={1}>
            <TextField name="Name" label={<TransField i18nKey="name" />} />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="countryId"
              label={<TransField i18nKey="country" />}
              options={countryOptions}
              showEmptyOption
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="countyName"
              label={<TransField i18nKey="county" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField name="cityName" label={<TransField i18nKey="city" />} />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="streetAddress"
              label={<TransField i18nKey="streetHouseNumber" />}
            />
          </Grid>

          <Grid item xs="auto" sx={{ ml: 'auto' }} alignItems="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="text"
                onClick={handleReset}
              >
                <TransButton i18nKey="resetFilters" />
              </Button>

              <Button
                variant="contained"
                type="submit"
                startIcon={<Icon name="search" size={20} />}
                disabled={!dirty || submitting}
              >
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
