import {
  AddButton,
  Button,
  CheckboxGroupField,
  formatPeriodDatesWithoutTime,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { TicketTextFilter, TicketTextType } from 'dto/ticketTexts';
import {
  getTicketTexts,
  setTicketTextsFilter,
} from 'features/ticketText/ticketTextActions';
import { ticketTextsFilterSelector } from 'features/ticketText/ticketTextSelectors';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useTicketTextsForm } from 'hooks/useTicketTextsForm';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { TicketTextStopField } from 'routes/TicketTexts/TicketTextStopField/TicketTextStopField';
import { useDispatch, useSelector } from 'store/utils';

interface TicketTextsSearchFormProps {}

const TICKET_TEXTS_FIELDS_TO_RESET = [
  'productId',
  'promotionalDiscountId',
  'serviceCode',
] as const;

export const TicketTextSearchForm: FC<TicketTextsSearchFormProps> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(ticketTextsFilterSelector);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);

  const onSubmit = useCallback(
    async (values: TicketTextFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(
          getTicketTexts({
            ...values,
            purchaseFromPeriod: formatPeriodDatesWithoutTime(
              values.purchaseFromPeriod
            ),
            purchaseToPeriod: formatPeriodDatesWithoutTime(
              values.purchaseToPeriod
            ),
            travelFromPeriod: formatPeriodDatesWithoutTime(
              values.travelFromPeriod
            ),
            travelToPeriod: formatPeriodDatesWithoutTime(values.travelToPeriod),
            offset: 0,
          })
        );
      }),
    [dispatch]
  );

  const initialValues = useMemo<Partial<TicketTextFilter>>(
    () => ({
      carrierId: carrierIdFormInitValue,
      ticketTextTypeIds: [
        TicketTextType.PRODUCT,
        TicketTextType.DISCOUNT,
        TicketTextType.SERVICE,
      ],
      ...filter,
    }),
    [carrierIdFormInitValue, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setTicketTextsFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit, values } = useForm({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const { carrierId } = values;
  const handleCarrierChange = useCallback(() => {
    form.batch(() =>
      TICKET_TEXTS_FIELDS_TO_RESET.forEach((field) => form.change(field))
    );
  }, [form]);

  const {
    productOptions,
    promotionalDiscountOptions,
    serviceOptions,
    typeOptions,
  } = useTicketTextsForm(carrierId);

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setTicketTextsFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm
      title={<TransTitle i18nKey="ticketTexts" />}
      action={
        <AddButton component={Link} to="ticket-texts/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="carrierId"
                label={<TransField i18nKey="carrier" />}
                onChange={handleCarrierChange}
                options={carrierOptions}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="productId"
                label={<TransField i18nKey="product" />}
                showEmptyOption
                options={productOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="promotionalDiscountId"
                label={<TransField i18nKey="promotionalDiscount" />}
                showEmptyOption
                options={promotionalDiscountOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="serviceCode"
                label={<TransField i18nKey="service" />}
                showEmptyOption
                options={serviceOptions}
              />
            </Grid>
            <Grid item xs={5}>
              <Grid container columns={5} spacing={2}>
                <PeriodField
                  from={{
                    name: 'purchaseFromPeriod',
                    label: <TransField i18nKey="purchaseDateFrom" />,
                    selectsRange: true,
                    isClearable: true,
                  }}
                  to={{
                    name: 'purchaseToPeriod',
                    label: <TransField i18nKey="purchaseDateTo" />,
                    selectsRange: true,
                    isClearable: true,
                  }}
                />
                <PeriodField
                  from={{
                    name: 'travelFromPeriod',
                    label: <TransField i18nKey="travelDateFrom" />,
                    selectsRange: true,
                    isClearable: true,
                  }}
                  to={{
                    name: 'travelToPeriod',
                    label: <TransField i18nKey="travelDateTo" />,
                    selectsRange: true,
                    isClearable: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <TicketTextStopField
                name="originStop"
                label={<TransField i18nKey="originStop" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TicketTextStopField
                name="destinationStop"
                label={<TransField i18nKey="destinationStop" />}
              />
            </Grid>
            <Grid item>
              <CheckboxGroupField
                name="ticketTextTypeIds"
                label={<TransField i18nKey="type" />}
                options={typeOptions}
                required
                inline
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
