import { Classifier } from '@fleet/shared/dto/classifier';
import { Product } from 'dto/product';
import { Service } from 'dto/service';
import { TicketText, TicketTextType } from 'dto/ticketTexts';
import {
  fetchProducts,
  fetchPromotionalDiscounts,
  fetchServices,
} from 'features/ticketText/ticketTextService';
import { TransField } from 'i18n/trans/field';
import _omit from 'lodash/omit';
import { useEffect, useMemo, useState } from 'react';
import { makeClassificationOptions } from '@fleet/shared';
import { uniqBy } from 'lodash';

export const TICKET_TEXT_TYPE_FIELDS = {
  [TicketTextType.PRODUCT]: 'product',
  [TicketTextType.DISCOUNT]: 'promotionalDiscount',
  [TicketTextType.SERVICE]: 'service',
} as const;

export const useTicketTextsForm = (carrierId: number | undefined) => {
  const [products, setProducts] = useState<Array<Product>>([]);
  const [promotionalDiscounts, setPromotionalDiscounts] = useState<
    Array<Classifier>
  >([]);
  const [services, setServices] = useState<Array<Service>>([]);

  const promotionalDiscountOptions =
    makeClassificationOptions(promotionalDiscounts);

  const productOptions = useMemo(
    () =>
      products.map(({ id, description }) => ({
        value: id,
        label: description,
      })),
    [products]
  );
  const serviceOptions = useMemo(
    () =>
      services.map(({ id, code }) => ({
        value: id,
        label: code,
      })),
    [services]
  );
  const typeOptions = useMemo(
    () =>
      Object.entries(TICKET_TEXT_TYPE_FIELDS).map(([key, label]) => ({
        value: key,
        label: <TransField i18nKey={label} />,
      })),
    []
  );

  useEffect(() => {
    const fetchData = async (carrierId: number) => {
      const [products, promotionalDiscounts, services] = await Promise.all([
        fetchProducts(carrierId),
        fetchPromotionalDiscounts(carrierId),
        fetchServices(carrierId),
      ]);

      setProducts(products);
      setPromotionalDiscounts(promotionalDiscounts);
      setServices(uniqBy(services, 'code'));
    };

    if (carrierId) {
      fetchData(carrierId);
    }
  }, [carrierId]);

  return {
    productOptions,
    promotionalDiscountOptions,
    serviceOptions,
    typeOptions,
  };
};

export const transformStop = (values: Partial<TicketText>) => ({
  ..._omit(values, ['originStop', 'destinationStop']),
  originStopId: values.originStop?.id,
  destinationStopId: values.destinationStop?.id,
});
