import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Button,
  CardHeader,
  ConfirmDeleteModal,
  DrawerForm,
  DrawerFormProps,
  FormControl,
  FormProvider,
  Icon,
  Tooltip,
  decimalToPercentage,
  formSubmit,
  percentageToDecimal,
  useForm,
  useModal,
} from '@fleet/shared';
import {
  ButtonGroup,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { OnHoldBookingFields } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationFields/OnHoldBookingFields';
import {
  clearOnHoldBookingConfiguration,
  createOrUpdateOnHoldBookingConfiguration,
  deleteOnHoldBookingConfigurations,
  getOnHoldBookingConfiguration,
  getOnHoldBookingConfigurations,
} from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationActions';
import { currentOnHoldBookingConfigurationSelector } from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationSelectors';
import { OnHoldBookingConfigurationDetailFormValues } from 'dto/onHoldBookingConfiguration';
import { onHoldBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { useHistory, useParams } from 'react-router-dom';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  TimeDuration,
  convertNumberToTimeDuration,
  convertTimeDurationToNumber,
} from '@fleet/shared/utils/timeDuration';
import { TransModal } from 'i18n/trans/modal';
import _omit from 'lodash/omit';

interface OnHoldBookingDetailsProps {}

export const OnHoldBookingConfigurationDetails: FC<OnHoldBookingDetailsProps> =
  () => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();
    const { open: isOpen, onOpen, onClose } = useModal();

    const loading = useSelector(onHoldBookingLoadingSelector);
    const currentOnHoldBooking = useSelector(
      currentOnHoldBookingConfigurationSelector
    );
    const { action, id } = useParams<{ action: string; id?: string }>();
    const isEditing = useMemo(
      () => action === 'edit' && Boolean(id),
      [action, id]
    );

    const handleGoBack = useCallback(() => {
      history.replace('/on-hold-bookings');
    }, [history]);

    const ignoreFormRestart = useRef(false);

    useEffect(() => {
      !ignoreFormRestart.current && dispatch(clearOnHoldBookingConfiguration());
      if (isEditing && id) {
        dispatch(getOnHoldBookingConfiguration(id))
          .unwrap()
          .catch(handleGoBack);
      }

      return () => {
        !ignoreFormRestart.current &&
          dispatch(clearOnHoldBookingConfiguration());
      };
    }, [dispatch, handleGoBack, id, isEditing]);

    const convertOptionalTimeFieldsToMinutes = (
      timeField?: TimeDuration
    ): number | null =>
      timeField ? convertTimeDurationToNumber(timeField) : null;

    const onSubmit = useCallback(
      async (values: OnHoldBookingConfigurationDetailFormValues) =>
        formSubmit(async () => {
          const {
            timeAfterBooking,
            timeBeforeDeparture,
            bookingAvailableBeforeDeparture,
            deadlineTimeAfterDeparture,
            notificationTimes,
            timeUntilDeparturePercentage,
            defaultPaymentDeadlineTime,
            validityPeriod: { from, to },
            ...rest
          } = values;
          const [dateFormat, timeFormat] = isoDateTimeFormat.split("'T'");

          const payload = {
            ...rest,
            notificationTimesInMinutes: notificationTimes.map((time) =>
              convertTimeDurationToNumber(time)
            ),
            timeAfterBookingInMinutes:
              rest.ticketPaymentDeadlineTypeId ===
              'TICKET_PAYMENT_DEADLINE_TYPE.FIXED_TIME'
                ? convertOptionalTimeFieldsToMinutes(timeAfterBooking)
                : null,
            timeBeforeDepartureInMinutes:
              convertOptionalTimeFieldsToMinutes(timeBeforeDeparture),
            conductorConfiguration: {
              bookingAvailableBeforeDepartureInMinutes:
                convertOptionalTimeFieldsToMinutes(
                  bookingAvailableBeforeDeparture
                ),
              deadlineAfterDepartureInMinutes:
                convertOptionalTimeFieldsToMinutes(deadlineTimeAfterDeparture),
            },
            timeUntilDeparturePercentage:
              rest.ticketPaymentDeadlineTypeId ===
                'TICKET_PAYMENT_DEADLINE_TYPE.PERCENTAGE_OF_TIME' &&
              timeUntilDeparturePercentage
                ? percentageToDecimal(timeUntilDeparturePercentage)
                : null,
            defaultPaymentDeadlineTime: defaultPaymentDeadlineTime
              ? formatDate(defaultPaymentDeadlineTime, timeFormat)
              : null,
            validityPeriod: {
              from: formatDate(from, dateFormat),
              to: formatDate(to, dateFormat),
            },
          };

          const data = await dispatch(
            createOrUpdateOnHoldBookingConfiguration(payload)
          ).unwrap();

          if (values.id) {
            alert.success(<TransAlert i18nKey="onHoldBookingUpdated" />);
          } else {
            alert.success(<TransAlert i18nKey="onHoldBookingCreated" />);
            history.replace(`/on-hold-bookings/edit/${data.id}`);
          }

          dispatch(getOnHoldBookingConfigurations());
        }),
      [alert, dispatch, history]
    );

    const initialValues: Partial<OnHoldBookingConfigurationDetailFormValues> =
      useMemo(() => {
        if (!currentOnHoldBooking) {
          return {
            ticketPaymentDeadlineTypeId:
              'TICKET_PAYMENT_DEADLINE_TYPE.FIXED_TIME',
            timeAfterBooking: convertNumberToTimeDuration(0),
            timeBeforeDeparture: convertNumberToTimeDuration(0),
            bookingAvailableBeforeDeparture: convertNumberToTimeDuration(0),
            deadlineTimeAfterDeparture: convertNumberToTimeDuration(0),
            notificationTimes: [],
            useAutoCancellation: false,
            isMain: false,
          };
        }

        const {
          ticketPaymentDeadlineType,
          timeAfterBookingInMinutes,
          timeBeforeDepartureInMinutes,
          conductorConfiguration,
          notificationTimesInMinutes,
          defaultPaymentDeadlineTime,
          timeUntilDeparturePercentage,
          ...rest
        } = currentOnHoldBooking;

        return {
          ...rest,
          ticketPaymentDeadlineTypeId: ticketPaymentDeadlineType.id,
          timeAfterBooking: convertNumberToTimeDuration(
            timeAfterBookingInMinutes ?? 0
          ),
          timeBeforeDeparture: convertNumberToTimeDuration(
            timeBeforeDepartureInMinutes ?? 0
          ),
          bookingAvailableBeforeDeparture: convertNumberToTimeDuration(
            conductorConfiguration?.bookingAvailableBeforeDepartureInMinutes ??
              0
          ),
          deadlineTimeAfterDeparture: convertNumberToTimeDuration(
            conductorConfiguration?.deadlineAfterDepartureInMinutes ?? 0
          ),
          notificationTimes: notificationTimesInMinutes?.map((minute) =>
            convertNumberToTimeDuration(minute)
          ),
          timeUntilDeparturePercentage: timeUntilDeparturePercentage
            ? decimalToPercentage(timeUntilDeparturePercentage)
            : null,
          // Hack to properly set initial value to time field
          defaultPaymentDeadlineTime: defaultPaymentDeadlineTime
            ? `2001-01-01T${defaultPaymentDeadlineTime}`
            : null,
        };
      }, [currentOnHoldBooking]);

    const { form, handleSubmit, dirty, submitting } =
      useForm<OnHoldBookingConfigurationDetailFormValues>({
        initialValues,
        onSubmit,
        subscription: { dirty: true, submitting: true, values: true },
      });

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    const handleCopy = useCallback(async () => {
      ignoreFormRestart.current = true;
      form.restart({
        ..._omit(initialValues, ['id']),
      });
      history.replace(`/on-hold-bookings/create`);
    }, [form, history, initialValues]);

    const handleDelete = useCallback(async () => {
      await dispatch(deleteOnHoldBookingConfigurations([id!])).unwrap();
      dispatch(clearOnHoldBookingConfiguration());
      alert.success(<TransAlert i18nKey="onHoldBookingDeleted" />);
      history.replace('/on-hold-bookings/');
    }, [alert, dispatch, history, id]);

    const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
      (_, reason) => {
        if (reason === 'close') {
          handleGoBack();
        }
      },
      [handleGoBack]
    );

    return (
      <DrawerForm open onClose={handleCloseEditForm}>
        <FormProvider form={form}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {isEditing ? (
                  currentOnHoldBooking?.name
                ) : loading ? (
                  <>&nbsp;</>
                ) : (
                  <TransSubtitle i18nKey="newOnHoldBookingConfiguration" />
                )}
              </Typography>
            }
            action={
              <ButtonGroup>
                {isEditing && (
                  <>
                    <Button
                      variant="text"
                      startIcon={<Icon name="clone" />}
                      onClick={handleCopy}
                    >
                      <TransButton i18nKey="copy" />
                    </Button>
                    <Button
                      variant="text"
                      color="error"
                      startIcon={<Icon name="trash" />}
                      onClick={onOpen}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                    <ConfirmDeleteModal
                      handleDelete={handleDelete}
                      title={<TransModal i18nKey="deleteOnHoldBooking" />}
                      description={
                        <TransModal i18nKey="onHoldBookingDeletionDescription" />
                      }
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  </>
                )}
                <IconButton aria-label="close" onClick={handleGoBack}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </ButtonGroup>
            }
          />
          <CardContent component="form" onSubmit={handleSubmit}>
            <Grid container columns={3} spacing={2}>
              <OnHoldBookingFields />
              <Grid item xs={12} sx={{ p: '0 !important' }} />
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" flexWrap="nowrap">
                  <FormControl label="&nbsp;">
                    <Button
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      onClick={handleReset}
                      disabled={
                        !ignoreFormRestart.current && (!dirty || submitting)
                      }
                    >
                      <TransButton i18nKey="resetChanges" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button
                      variant="contained"
                      icon="check"
                      type="submit"
                      disabled={submitting}
                    >
                      <TransButton i18nKey="save" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </FormProvider>
      </DrawerForm>
    );
  };
